<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
      <img
        class="dropdown-icon"
        src="../../assets/images/dropDownIcon.svg"
        alt=""
      />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option.name;
          open = false;
          $emit('input', option.eng);
        "
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selected: this.options.length > 0 ? this.options[0].name : null,
      open: false
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: right;
  outline: none;
  /*height: 47px;*/
  /*line-height: 47px;*/
  direction: rtl;
  border-radius: 5px;
}

.selected {
  /*background-color: #080D0E;*/
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 6px;
  /*border: 1px solid #858586;*/
  /*color: #ffffff;*/
  color: #121212;
  /*padding-right: 8px;*/
  padding: 3px 15px;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-icon {
  float: left;
}

/*.selected.open {*/
/*  border: 1px solid #CE9B2C;*/
/*  border-radius: 6px 6px 0px 0px;*/
/*}*/

/*.selected:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  top: 22px;*/
/*  right: 10px;*/
/*  width: 0;*/
/*  height: 0;*/
/*  border: 4px solid transparent;*/
/*  border-color: #fff transparent transparent transparent;*/
/*}*/

.items {
  margin-top: 10px;
  /*color: #ffffff;*/
  /*border-radius: 0px 0px 6px 6px;*/
  overflow: hidden;
  /*border-right: 1px solid #CE9B2C;*/
  /*border-left: 1px solid #CE9B2C;*/
  /*border-bottom: 1px solid #CE9B2C;*/
  position: absolute;
  /*background-color: #080D0E;*/
  left: 0;
  right: 0;
  background: #ffffff;
  /* Drop shadow */

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.item {
  /*color: #ffffff;*/
  color: #121212;
  padding-right: 15px;
  cursor: pointer;
  user-select: none;
}

.item:hover {
  background-color: rgba(31, 60, 136, 0.2);
}

.selectHide {
  display: none;
}
</style>
