<template>
  <div id="support">
    <loading :can-cancel="true"></loading>
    <SupportStatusModal
      :data="ticketMessage"
      v-if="showTicketMessages"
      @closeModal="showTicketMessages = false"
    />
    <div class="support-header">
      <PanelHeader title="پشتیبانی" />
    </div>
    <div class="support-container">
      <div class="support-send-massage-wrapper">
        <div class="support-send-massage__header-section">
          <div class="header-section__subject-input">
            <div class="label">موضوع</div>
            <input
              type="text"
              id="subject-input"
              class="input"
              v-model="subject"
              ref="subject"
              :class="{ error: errorMassage.subject }"
            />
            <div class="error-massage" v-if="errorMassage.subject">
              {{ errorMassage.subject }}
            </div>
          </div>
          <div class="header-section__unit-dropdown">
            <div class="label">واحد</div>
            <CustomSelect
              :options="options"
              :default="'پیگیری سفارش'"
              @change="selectedInput"
              class="select"
              @input="selectedHandler"
            />
          </div>
          <div class="header-section__order-number">
            <div class="label">شماره سفارش</div>
            <input
              type="text"
              class="input"
              v-model="order_number"
              :class="{ error: errorMassage.order_number }"
              ref="order_number"
            />
            <div class="error-massage" v-if="errorMassage.order_number">
              {{ errorMassage.order_number }}
            </div>
            <div class="order-number__information-text">
              اگر تیکت مربوط به سفارشات شما است، لطفا شماره وارد کنید
            </div>
          </div>
        </div>
        <div class="support-send-massage__textarea-section">
          <textarea
            name=""
            id=""
            class="textarea-element"
            placeholder="متن پیام"
            v-model="message"
            ref="message"
            :class="{ error: errorMassage.message }"
          ></textarea>
          <div class="error-massage" v-if="errorMassage.message">
            {{ errorMassage.message }}
          </div>
        </div>
        <div class="support-send-massage__button-wrapper">
          <div class="send-massage-button-wrapper">
            <button class="send-massage-button" @click="submit">
              ارسال پیام
            </button>
          </div>
          <div class="send-file-button-wrapper">
            <button class="send-file-button">
              <img
                src="../assets/images/upload-image.svg"
                class="upload-image"
                alt=""
              /><span class="button-title">
                {{ file_name || "ارسال فایل" }} </span
              ><input type="file" class="upload-file" @change="previewFiles" />
            </button>
          </div>
        </div>
      </div>
      <div class="support-table__wrapper">
        <table class="table-wrapper" cellspacing="0">
          <tr>
            <th class="table-header-item">موضوع</th>
            <th class="table-header-item">واحد پشتیبانی</th>
            <th class="table-header-item">زمان</th>
            <th class="table-header-item">وضعیت</th>
            <th class="table-header-item">جزئیات</th>
          </tr>
          <tr v-for="ticket in tickets" :key="ticket.id">
            <td>{{ ticket.subject }}</td>
            <td>{{ handleSection[ticket.section].name }}</td>
            <td>{{ $G2J(ticket.created_at) }}</td>
            <td
              class="pending"
              :style="{ color: handleStatus[ticket.status]['color'] }"
            >
              {{ handleStatus[ticket.status].name }}
            </td>
            <td class="status-image" @click="handlerTicketMessages(ticket.id)">
              <img src="../assets/images/carbon_view.svg" alt="" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import CustomSelect from "@/components/SelectInput/CustomSelect";
import SupportStatusModal from "@/components/Modals/SupportStatusModal";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "../Utility/georgianToJalali";

export default {
  components: { SupportStatusModal, CustomSelect, PanelHeader, Loading },
  data() {
    return {
      tickets: [],
      errorMassage: {},
      section: null,
      pending: false,
      has_been_answered: false,
      file_name: "",
      showTicketMessages: false,
      subject: "",
      order_number: "",
      message: "",
      file: "",
      ticketMessage: null,
      options: [
        {
          id: 1,
          name: "پیگیری سفارش",
          eng: "order_tracking"
        },
        {
          id: 2,
          name: "احراز هویت",
          eng: "authentication"
        },
        {
          id: 3,
          name: "مالی",
          eng: "financial"
        },
        {
          id: 4,
          name: "سایر",
          eng: "other"
        }
      ],
      handleStatus: {
        process: { color: "#FB9224", name: "در حال بررسی" },
        answered: { color: "#039100", name: "پاسخ داده شده" },
        reject: { color: "#DC0000", name: "رد شده" }
      },
      handleSection: {
        order_tracking: { name: "پیگیری سفارش" },
        authentication: { name: "احراز هویت" },
        financial: { name: "مالی" },
        other: { name: "سایر" }
      }
    };
  },
  mounted() {
    this.getAllTickets();
  },
  methods: {
    async handlerTicketMessages(id) {
      let loader = this.$loading.show();
      try {
        let response = await axios(`/panel/ticket/message/${id}`);
        console.log(response.data);
        if (response.data.length <= 0) {
          this.$toast.info("پیامی برای نمایش وجود ندارد");
          loader.hide();
          return false;
        }
        this.ticketMessage = response.data;
        this.showTicketMessages = true;
        loader.hide();
      } catch (error) {
        loader.hide();
      }
      loader.hide();
    },
    selectedInput(event) {
      this.section = event.target.value;
    },
    previewFiles(event) {
      this.file = event.target.files[0];
      this.file_name = event.target.files[0].name;
    },
    showModalHandler() {
      this.showModal = !this.showModal;
    },
    submit() {
      this.errorMassage = {};
      if (this.subject && this.section && this.message) {
        let fd = new FormData();
        fd.append("subject", this.subject);
        fd.append("section", this.section);
        fd.append("order_number", this.order_number);
        fd.append("message", this.message);
        if (this.file) {
          fd.append("file", this.file);
        }

        let loader = this.$loading.show();
        axios({
          url: "/panel/ticket",
          method: "POST",
          data: fd
        })
          .then(response => {
            this.subject = null;
            this.order_number = null;
            this.message = null;
            this.getAllTickets();
            loader.hide();
            this.$toast.success(response.data.message);
            // console.log(response.data);
          })
          .catch(error => {
            this.subject = null;
            this.order_number = null;
            this.message = null;
            loader.hide();
            console.log(error.response);
          });
      }
      if (!this.subject) {
        this.errorMassage.subject = "موضوع را وارد کنید";
        this.$refs.subject.focus();
        return false;
      }
      if (!this.section) {
        this.errorMassage.option = "واحد را انتخاب کنید";
        this.$refs.option.focus();
        return false;
      }
      if (!this.order_number) {
        this.errorMassage.order_number = "شماره سفارش را وارد کنید";
        this.$refs.order_number.focus();
        return false;
      }
      if (!this.message) {
        this.errorMassage.message = "متن پیام را بنویسید";
        this.$refs.message.focus();
      }
    },
    selectedHandler(value) {
      this.section = value;
    },
    getAllTickets() {
      let loader = this.$loading.show();
      axios("panel/ticket")
        .then(response => {
          loader.hide();
          this.tickets = response.data;
        })
        .catch(error => {
          loader.hide();
          console.log(error.response);
        });
    }
  }
};
</script>

<style scoped>
#support {
  width: 100%;
  margin-bottom: 20px;
}

.support-container {
  padding: 0 10px;
}

.support-send-massage-wrapper {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 30px 30px 20px 30px;
}

.support-send-massage__header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.support-send-massage__button-wrapper {
  display: flex;
  /*justify-content: flex-end;*/
  /*gap: 150px;*/
  /*position: relative;*/
  /*display: flex;*/
  justify-content: center;
  gap: 10px;
  position: relative;
  flex-wrap: wrap;
}

.label {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
}

.header-section__subject-input {
  width: 280px;
}

.header-section__unit-dropdown {
  width: 280px;
}

.header-section__order-number {
  width: 280px;
}

.order-number__information-text {
  margin-top: 5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: rgba(18, 18, 18, 0.7);
}

.input {
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 4px 10px;
}

.textarea-element {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background: rgba(31, 60, 136, 0.1);
  border-radius: 10px;
  min-height: 150px;
  resize: none;
}

.send-massage-button-wrapper {
  max-width: 480px;
  /*position: absolute;*/
  /*right: 50%;*/
  /*transform: translateX(50%);*/
  width: 100%;
}

.send-massage-button {
  width: 100%;
  background: #1f3c88;
  border-radius: 10px;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 8px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.send-file-button-wrapper {
  width: 130px;
}

.send-file-button {
  position: relative;
  width: 100%;
  background: #1f3c88;
  border-radius: 10px;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 7px 15px 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.upload-file {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.upload-image {
  width: 25px;
}

.button-title {
  vertical-align: 10px;
  margin-right: 5px;
}

.support-table__wrapper {
  margin-top: 20px;
  width: 100%;
  overflow: auto;
}

.footer-wrapper {
  margin-top: 20px;
}

.table-wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

th {
  background-color: rgba(31, 60, 136, 0.2);
  padding: 10px;
  font-size: 14px;
}

td {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}

.pending {
  color: #fb9224;
}

.has-been-answered {
  color: #039100;
}

.status-image {
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b !important;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 1140px) {
  #support {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .support-container {
    padding: 0 40px;
  }

  .send-massage-button-wrapper {
    max-width: 480px;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
  }

  .support-send-massage__button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 150px;
    position: relative;
    /*display: flex;*/
    /*justify-content: center;*/
    /*gap: 10px;*/
    /*position: relative;*/
    /*flex-wrap: wrap;*/
  }

  .table-wrapper {
    overflow: hidden;
  }

  .support-table__wrapper {
    overflow: initial;
  }

  th {
    font-size: 16px;
  }

  td {
    font-size: 16px;
  }
}
</style>
