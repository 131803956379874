<template>
  <div id="modal">
    <IssuedInsurances @closeModal="$emit('closeModal')" :data="data" />
  </div>
</template>

<script>
import IssuedInsurances from "@/components/SupportMessage";

export default {
  name: "SupportStatusModal",
  components: { IssuedInsurances },
  props: {
    data: {
      required: true
    }
  }
};
</script>

<style scoped>
#modal {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
}
</style>
