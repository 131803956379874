<template>
  <div class="support-massage-container">
    <loading :can-cancel="true"></loading>
    <div id="support-massage-wrapper">
      <img
        src="../assets/images/close-icon.svg"
        alt=""
        @click="closeModal"
        style="cursor: pointer"
      />
      <div class="message-container">
        <h6 class="message-subject">{{ data[0].subject }}</h6>
        <div class="messages" v-for="item in data" :key="item.id">
          <div class="message" v-if="item.type === 'Client'">
            {{ item.text }}
            <div class="message-sent-time">
              {{ item.jalali_created_at }} {{ item.fullname }}
            </div>
          </div>
          <div class="message" v-if="item.image">
            <img :src="item.image" alt="" style="width: 100px;height: 100px" />
            <div class="message-sent-time">
              {{ item.jalali_created_at }} {{ item.fullname }}
            </div>
          </div>
          <div class="message support" v-if="item.type === 'agent'">
            {{ item.text }}
            <div class="message-sent-time">
              {{ item.jalali_created_at }} {{ item.fullname }}
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <button class="submit" @click="submit">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4504 11.11L2.45037 0.110006C2.27794 0.0237731 2.08426 -0.0107787 1.89265 0.0105114C1.70104 0.0318016 1.51967 0.108026 1.37037 0.230006C1.2278 0.349499 1.12138 0.506393 1.06308 0.68305C1.00479 0.859707 0.996921 1.04912 1.04037 1.23001L3.69037 11H15.0004V13H3.69037L1.00037 22.74C0.959599 22.8911 0.95484 23.0496 0.986477 23.2028C1.01811 23.356 1.08527 23.4997 1.18253 23.6223C1.2798 23.7448 1.40446 23.8428 1.54651 23.9084C1.68855 23.9741 1.844 24.0054 2.00037 24C2.15692 23.9991 2.31105 23.9614 2.45037 23.89L24.4504 12.89C24.6142 12.8061 24.7516 12.6786 24.8476 12.5216C24.9436 12.3645 24.9944 12.1841 24.9944 12C24.9944 11.816 24.9436 11.6355 24.8476 11.4785C24.7516 11.3214 24.6142 11.1939 24.4504 11.11Z"
              fill="#183573"
            />
          </svg>
        </button>
        <input type="text" v-model="message" />
        <div class="file-preview">
          {{ file_name }}
        </div>
        <button class="select-file" style="position: relative">
          <input type="file" class="file-input" @change="previewFiles" />
          <svg
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.26 5.8795C22.26 6.77282 22.0833 7.63615 21.73 8.46948C21.3767 9.30281 20.8867 10.0328 20.26 10.6595L11.3 19.5394L10.06 18.3194L19.02 9.43947C20.0067 8.47948 20.5 7.29282 20.5 5.8795C20.5 4.69284 20.11 3.70951 19.33 2.92951C18.55 2.14952 17.5667 1.75952 16.38 1.75952C15.02 1.75952 13.82 2.25952 12.78 3.25951L2.92 13.0395C2.13333 13.8261 1.74 14.7128 1.74 15.6994C1.74 16.4594 1.98667 17.0828 2.48 17.5694C2.97333 18.0561 3.6 18.2994 4.36 18.2994C5.36 18.2994 6.25333 17.9061 7.04 17.1194L14.58 9.67947C15.2867 8.94614 15.64 8.29948 15.64 7.73948C15.64 7.45949 15.5333 7.23949 15.32 7.07949C15.1067 6.91949 14.8533 6.83949 14.56 6.83949C13.92 6.83949 13.3467 7.10615 12.84 7.63948L6.04 14.3594L4.82 13.1395L11.6 6.41949C12.48 5.5395 13.4667 5.0995 14.56 5.0995C15.3333 5.0995 16 5.34617 16.56 5.8395C17.12 6.33283 17.4 6.95949 17.4 7.71948C17.4 8.75948 16.8733 9.81947 15.82 10.8995L8.28 18.3394C7.16 19.4594 5.85333 20.0194 4.36 20.0194C3.12 20.0194 2.08333 19.6094 1.25 18.7894C0.416667 17.9694 0 16.9394 0 15.6994C0 14.2328 0.56 12.9395 1.68 11.8195L11.54 2.03952C12.9267 0.69286 14.54 0.0195312 16.38 0.0195312C18.0467 0.0195312 19.4433 0.579528 20.57 1.69952C21.6967 2.81951 22.26 4.21284 22.26 5.8795Z"
              fill="#183573"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: { Loading },
  name: "IssuedInsurances",
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      message: "",
      file: null,
      file_name: null
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async submit() {
      let fd = new FormData();
      fd.append("text", this.message);
      fd.append("ticket_id", this.data[0].ticket_id);
      if (this.file) {
        fd.append("image", this.file);
      }

      let loader = this.$loading.show();
      try {
        let response = await axios({
          url: "/panel/ticket/message",
          method: "POST",
          data: fd
        });
        console.log(response.data);
        await this.getMessages();
        this.message = "";
        this.file_name = null;
        this.file = null;
        loader.hide();
        this.$toast.success(response.data.message);
      } catch (error) {
        console.log(error.data);
        loader.hide();
      }
    },
    previewFiles(event) {
      this.file = event.target.files[0];
      this.file_name = event.target.files[0].name;
    },
    async getMessages() {
      let response = await axios(
        `/panel/ticket/message/${this.data[0].ticket_id}`
      );
      this.data = response.data;
    }
  }
};
</script>

<style scoped lang="scss">
.select-file {
  cursor: pointer;
}

.file-input {
  opacity: 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
}

.submit {
  cursor: pointer;
}

.support-massage-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);
}
#support-massage-wrapper {
  max-width: 834px;
  width: 100%;
  min-height: 429px;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(21, 47, 102, 0.3);
  border-radius: 10px;
  padding: 10px;
}
h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  margin-bottom: 43px;
}

.message-container {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  //background: #f3f3f3;

  margin-bottom: 25px;
  //padding: 15px 30px;

  .message-subject {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  .messages {
    padding-top: 50px;
    display: flex;
    flex-direction: column;

    .message {
      width: 100%;
      max-width: 530px;
      padding: 23px 27px 5px 10px;
      background-color: rgba(31, 60, 136, 0.4);
      color: #121212;
      border-radius: 10px;
      border-top-right-radius: 0;
      margin-bottom: 35px;
      font-size: 14px;
      line-height: 22px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        height: 30px;
        width: 18px;
        border-bottom-right-radius: 30px;
        top: 2px;
        transform: translateY(-100%);
        box-shadow: 0 15px 0 0 #a7b1c6;
        right: 0;
      }

      .message-sent-time {
        color: rgba(31, 60, 136, 1);
        text-align: left;
        font-size: 10px;
        line-height: 16px;
      }
    }

    .message.support {
      border-top-right-radius: 10px;
      border-top-left-radius: 0;
      background-color: rgba(31, 60, 136, 0.1);
      align-self: flex-end;
      //#F3F3F3
      &::before {
        background-color: transparent;
        height: 30px;
        width: 18px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 30px;
        top: 2px;
        transform: translateY(-100%);
        box-shadow: 0 15px 0 0 #e9eaed;
        right: initial;
        left: 0;
      }
    }
  }
}

.input-container {
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: flex;
  border: 1px solid #1f3c88;
  border-radius: 10px;
  //background-color: #f3f3f3;
  padding: 4px 8px;

  input {
    flex-grow: 1;
    padding: 0 10px;
    color: #121212;
    background: none;
    border: none;
    outline: none;
  }

  button {
    width: 32px;
    background: none;
    border: none;
    outline: none;
  }
}
</style>
